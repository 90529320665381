import {createSlice} from '@reduxjs/toolkit'
import {initialState} from './state'
import * as services from './services'

// Creating slice with reducers
const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [services.getUser.pending]: (state) => {
      state.user.isLoading = true
      state.user.isSuccess = false
      state.user.error = null
    },
    [services.getUser.rejected]: (state, action) => {
      state.user.isLoading = false
      state.user.error = action.payload
      services.deleteAuthToken()
    },
    [services.getUser.fulfilled]: (state, action) => {
      state.user.isLoading = false
      state.user.isSuccess = true
      state.user.data = action.payload.data
    },

    [services.updateUser.pending]: (state) => {
      state.update.isLoading = true
      state.update.isSuccess = false
      state.update.error = null
    },
    [services.updateUser.rejected]: (state, action) => {
      state.update.isLoading = false
      state.update.error = action.payload
    },
    [services.updateUser.fulfilled]: (state, action) => {
      state.update.isLoading = false
      state.update.isSuccess = true
    },

    [services.changePassword.pending]: (state) => {
      state.password.isLoading = true
      state.password.isSuccess = false
      state.password.error = null
    },
    [services.changePassword.rejected]: (state, action) => {
      state.password.isLoading = false
      state.password.error = action.payload
    },
    [services.changePassword.fulfilled]: (state, action) => {
      state.password.isLoading = false
      state.password.isSuccess = true
    },

    [services.updateUserPhoto.pending]: (state) => {
      state.photo.isLoading = true
      state.photo.isSuccess = false
      state.photo.error = null
    },
    [services.updateUserPhoto.rejected]: (state, action) => {
      state.photo.isLoading = false
      state.photo.error = action.payload
    },
    [services.updateUserPhoto.fulfilled]: (state) => {
      state.photo.isLoading = false
      state.photo.isSuccess = true
    },

    [services.logout.pending]: (state) => {
      state.logout.isLoading = true
      state.logout.isSuccess = false
      state.logout.error = null
    },
    [services.logout.rejected]: (state, action) => {
      state.logout.isLoading = false
      state.logout.error = action.payload.data
    },
    [services.logout.fulfilled]: (state, action) => {
      state.logout.isLoading = false
      state.logout.isSuccess = true
      state.token = null
      services.deleteAuthToken(action.payload)
      state.iAuth = false
      state.user.data = null
    },

    // courses
    [services.getUserCourses.pending]: (state, action) => {
      if (action.meta.arg && action.meta.arg.reset) {
        state.courses.data = []
        state.courses.meta = null
      }
      state.courses.isLoading = true
      state.courses.isSuccess = false
      state.courses.error = null
    },
    [services.getUserCourses.rejected]: (state, action) => {
      state.courses.isLoading = false
      state.courses.error = action.payload
    },
    [services.getUserCourses.fulfilled]: (state, action) => {
      state.courses.isLoading = false
      state.courses.isSuccess = true
      state.courses.data = action.payload.data
      state.courses.meta = action.payload.meta
    },

    // communications
    [services.getUserCommunications.pending]: (state, action) => {
      if (action.meta.arg && action.meta.arg.reset) {
        state.communications.data = []
        state.communications.meta = null
      }
      state.communications.isLoading = true
      state.communications.isSuccess = false
      state.communications.error = null
    },
    [services.getUserCommunications.rejected]: (state, action) => {
      state.communications.isLoading = false
      state.communications.error = action.payload
    },
    [services.getUserCommunications.fulfilled]: (state, action) => {
      state.communications.isLoading = false
      state.communications.isSuccess = true
      state.communications.data = action.payload.data
      state.communications.meta = action.payload.meta
    },

    // webinars
    [services.getUserWebinars.pending]: (state, action) => {
      if (action.meta.arg && action.meta.arg.reset) {
        state.webinars.data = []
        state.webinars.meta = null
      }
      state.webinars.isLoading = true
      state.webinars.isSuccess = false
      state.webinars.error = null
    },
    [services.getUserWebinars.rejected]: (state, action) => {
      state.webinars.isLoading = false
      state.webinars.error = action.payload
    },
    [services.getUserWebinars.fulfilled]: (state, action) => {
      state.webinars.isLoading = false
      state.webinars.isSuccess = true
      state.webinars.data = action.payload.data
      state.webinars.meta = action.payload.meta
    },

    // moderating
    [services.getUserModerating.pending]: (state, action) => {
      if (action.meta.arg && action.meta.arg.reset) {
        state.moderating.data = []
        state.moderating.meta = null
      }
      state.moderating.isLoading = true
      state.moderating.isSuccess = false
      state.moderating.error = null
    },
    [services.getUserModerating.rejected]: (state, action) => {
      state.moderating.isLoading = false
      state.moderating.error = action.payload
    },
    [services.getUserModerating.fulfilled]: (state, action) => {
      state.moderating.isLoading = false
      state.moderating.isSuccess = true
      state.moderating.data = action.payload.data
      state.moderating.meta = action.payload.meta
    }
  }
})

export default slice.reducer

