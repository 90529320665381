import axios from 'axios'

const configApiClient = {
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
  headers: {
    Accept: 'application/json',
    'Content-Type':
      'application/json;multipart/form-data;application/x-www-form-urlencoded'
  },
  withCredentials: false
}

const $apiClient = axios.create(configApiClient)

export {$apiClient}
