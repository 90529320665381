import {createSlice} from '@reduxjs/toolkit'
import {initialState} from './state'
import * as services from './services'

// Creating slice with reducers
const slice = createSlice({
  name: 'courses',
  initialState,
  extraReducers: {
    [services.getCourses.pending]: (state, action) => {
      if (action.meta.arg && action.meta.arg.reset) {
        state.courses.data = []
        state.courses.meta = null
      }
      state.courses.isLoading = true
      state.courses.isSuccess = false
      state.courses.error = null
    },
    [services.getCourses.rejected]: (state, action) => {
      state.courses.isLoading = false
      state.courses.error = action.payload
    },
    [services.getCourses.fulfilled]: (state, action) => {
      state.courses.isLoading = false
      state.courses.isSuccess = true
      state.courses.data = action.payload.data
      state.courses.meta = action.payload.meta
    },

    [services.getCourse.pending]: (state) => {
      state.course.isLoading = true
      state.course.isSuccess = false
      state.course.error = null
    },
    [services.getCourse.rejected]: (state, action) => {
      state.course.isLoading = false
      state.course.error = action.payload
    },
    [services.getCourse.fulfilled]: (state, action) => {
      state.course.isLoading = false
      state.course.isSuccess = true
      state.course.data = action.payload.data
    },

    [services.getLesson.pending]: (state) => {
      state.lesson.isLoading = true
      state.lesson.isSuccess = false
      state.lesson.error = null
    },
    [services.getLesson.rejected]: (state, action) => {
      state.lesson.isLoading = false
      state.lesson.error = action.payload
    },
    [services.getLesson.fulfilled]: (state, action) => {
      state.lesson.isLoading = false
      state.lesson.isSuccess = true
      state.lesson.data = action.payload.data
    },

    [services.finishLesson.pending]: (state) => {
      state.finished.isLoading = true
      state.finished.isSuccess = false
      state.finished.error = null
    },
    [services.finishLesson.rejected]: (state, action) => {
      state.finished.isLoading = false
      state.finished.error = action.payload
    },
    [services.finishLesson.fulfilled]: (state, action) => {
      state.finished.isLoading = false
      state.finished.isSuccess = true
    }
  }
})

export default slice.reducer
