import {createSlice} from '@reduxjs/toolkit'
import {initialState} from './state'
import * as services from './services'
import {addSubscription} from './services'

// Creating slice with reducers
const slice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    clearSubscribe: state => {
      state.subscribe.data = null
    }
  },
  extraReducers: {
    [services.getSubscriptions.pending]: (state) => {
      state.subscriptions.isLoading = true
      state.subscriptions.isSuccess = false
      state.subscriptions.error = null
    },
    [services.getSubscriptions.rejected]: (state, action) => {
      state.subscriptions.isLoading = false
      state.subscriptions.error = action.payload
    },
    [services.getSubscriptions.fulfilled]: (state, action) => {
      state.subscriptions.isLoading = false
      state.subscriptions.isSuccess = true
      state.subscriptions.data = action.payload.data
    },

    [services.addSubscription.pending]: (state, action) => {
      state.subscribe.isLoading = true
      state.subscribe.isSuccess = false
      state.subscribe.error = null
      if (action.meta.arg && action.meta.arg.slug) {
        state.subscribe.data = action.meta.arg.slug
      }
    },
    [services.addSubscription.rejected]: (state, action) => {
      state.subscribe.isLoading = false
      state.subscribe.error = action.payload
      state.subscribe.data = null
    },
    [services.addSubscription.fulfilled]: (state, action) => {
      state.subscribe.isLoading = false
      state.subscribe.isSuccess = true
      if (action.meta.arg && action.meta.arg.slug) {
        state.subscribe.data = action.meta.arg.slug
      }
    },

    [services.cancelSubscription.pending]: (state) => {
      state.cancel.isLoading = true
      state.cancel.isSuccess = false
      state.cancel.error = null
    },
    [services.cancelSubscription.rejected]: (state, action) => {
      state.cancel.isLoading = false
      state.cancel.error = action.payload
    },
    [services.cancelSubscription.fulfilled]: (state) => {
      state.cancel.isLoading = false
      state.cancel.isSuccess = true
    },

    [services.renewSubscription.pending]: (state) => {
      state.renew.isLoading = true
      state.renew.isSuccess = false
      state.renew.error = null
    },
    [services.renewSubscription.rejected]: (state, action) => {
      state.renew.isLoading = false
      state.renew.error = action.payload
    },
    [services.renewSubscription.fulfilled]: (state) => {
      state.renew.isLoading = false
      state.renew.isSuccess = true
    }
  }
})
export const {clearSubscribe} = slice.actions

export default slice.reducer
