import {$apiClient} from '../../../utility/axios'
import {createAsyncThunk} from '@reduxjs/toolkit'
import {objectToQueryString} from '../../../utility/commons'

export const getSubscriptions = createAsyncThunk(`subscriptions/getSubscriptions`, async () => {
  const query = {
    include: 'communications'
  }

  let url = '/plans'
  url = objectToQueryString(url, query)
  return await $apiClient.get(url)
})

export const addSubscription = createAsyncThunk(`subscriptions/addSubscription`, async ({slug}) => {
  const url = `/plans/${slug}/subscribe`
  return await $apiClient.post(url)
})

export const cancelSubscription = createAsyncThunk(`subscriptions/cancelSubscription`, async (slug) => {
  const url = `/plans/${slug}/unsubscribe`
  return await $apiClient.post(url)
})

export const renewSubscription = createAsyncThunk(`subscriptions/renewSubscription`, async (slug) => {
  const url = `/plans/${slug}/renew`
  return await $apiClient.post(url)
})
