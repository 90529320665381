// The initial state of the Courses container
export const initialState = {
  courses: {
    data: [],
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  },
  course: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  },
  lesson: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  },
  finished: {
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  }
}
