// The initial state of the Webinar container
export const initialState = {
  iAuth: !!localStorage.getItem('access_token'),
  token: localStorage.getItem('access_token') || null,
  login: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  logout: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  signin: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  change: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  reset: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  restore: {
    isLoading: false,
    isSuccess: false,
    error: null
  }
}
