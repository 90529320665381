import {createSlice} from '@reduxjs/toolkit'
import {initialState} from './state'
import * as services from './services'

// Creating slice with reducers
const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    requestLogout: state => {
      state.token = null
      state.iAuth = false
      services.deleteAuthToken()
    }
  },
  extraReducers: {
    [services.login.pending]: (state) => {
      state.login.isLoading = true
      state.login.isSuccess = false
      state.login.error = null
    },
    [services.login.rejected]: (state, action) => {
      state.login.isLoading = false
      state.login.error = action.payload
      services.deleteAuthToken()
    },
    [services.login.fulfilled]: (state, action) => {
      state.login.isLoading = false
      state.login.isSuccess = true
      state.token = action.payload.access_token
      services.setAuthToken(action.payload.access_token)
      state.iAuth = true
    },

    [services.logout.pending]: (state) => {
      state.logout.isLoading = true
      state.logout.isSuccess = false
      state.logout.error = null
    },
    [services.logout.rejected]: (state, action) => {
      state.logout.isLoading = false
      state.logout.error = action.payload
    },
    [services.logout.fulfilled]: (state, action) => {
      state.logout.isLoading = false
      state.logout.isSuccess = true
      state.token = null
      services.deleteAuthToken()
      state.iAuth = false
    },

    [services.signIn.pending]: (state) => {
      state.signin.isLoading = true
      state.signin.isSuccess = false
      state.signin.error = null
    },
    [services.signIn.rejected]: (state, action) => {
      state.signin.isLoading = false
      state.signin.error = action.payload
      services.deleteAuthToken()
    },
    [services.signIn.fulfilled]: (state, action) => {
      state.signin.isLoading = false
      state.signin.isSuccess = true
      state.token = action.payload.access_token
      services.setAuthToken(action.payload.access_token)
      state.iAuth = true
    },

    [services.changePassword.pending]: (state) => {
      state.change.isLoading = true
      state.change.isSuccess = false
      state.change.error = null
    },
    [services.changePassword.rejected]: (state, action) => {
      state.change.isLoading = false
      state.change.error = action.payload
    },
    [services.changePassword.fulfilled]: (state, action) => {
      state.change.isLoading = false
      state.change.isSuccess = true
    },

    [services.resetPassword.pending]: (state) => {
      state.reset.isLoading = true
      state.reset.isSuccess = false
      state.reset.error = null
    },
    [services.resetPassword.rejected]: (state, action) => {
      state.reset.isLoading = false
      state.reset.error = action.payload
    },
    [services.resetPassword.fulfilled]: (state, action) => {
      state.reset.isLoading = false
      state.reset.isSuccess = true
    },

    [services.restorePassword.pending]: (state) => {
      state.restore.isLoading = true
      state.restore.isSuccess = false
      state.restore.error = null
    },
    [services.restorePassword.rejected]: (state, action) => {
      state.restore.isLoading = false
      state.restore.error = action.payload
    },
    [services.restorePassword.fulfilled]: (state, action) => {
      state.restore.isLoading = false
      state.restore.isSuccess = true
      state.token = action.payload.access_token
      services.setAuthToken(action.payload.access_token)
      state.iAuth = true
    }
  }
})

export const {requestLogout} = slice.actions

export default slice.reducer

