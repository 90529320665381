// The initial state of the Webinar container
export const initialState = {
  subscriptions: {
    data: [],
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  },
  subscribe: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  },
  cancel: {
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  },
  renew: {
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  }
}
