import {createSlice} from '@reduxjs/toolkit'
import {initialState} from './state'
import * as services from './services'

// Creating slice with reducers
const slice = createSlice({
  name: 'faq',
  initialState,
  reducers: {},
  extraReducers: {
    [services.getFaq.pending]: (state, action) => {
      if (action.meta.arg && action.meta.arg.reset) {
        state.faq.data = []
        state.faq.meta = null
      }
      state.faq.isLoading = true
      state.faq.isSuccess = false
      state.faq.error = null
    },
    [services.getFaq.rejected]: (state, action) => {
      state.faq.isLoading = false
      state.faq.error = action.payload
    },
    [services.getFaq.fulfilled]: (state, action) => {
      state.faq.isLoading = false
      state.faq.isSuccess = true
      state.faq.data = action.payload.data
      state.faq.meta = action.payload.meta
    }
  }
})

export default slice.reducer
