// The initial state of the Contacts container
export const initialState = {
  contacts: {
    data: [],
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  }
}
