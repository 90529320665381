import {$apiClient} from '../../../utility/axios'
import {objectToQueryString} from '../../../utility/commons'
import {createAsyncThunk} from '@reduxjs/toolkit'

export const getCourses = createAsyncThunk(`courses/getCourses`, async ({params}) => {
  const query = Object.assign({
    per_page: 9
  }, params)

  let url = '/courses'
  url = objectToQueryString(url, query)
  return await $apiClient.get(url)
})

export const getCourse = createAsyncThunk(`courses/getCourse`, async (slug) => {
  const url = `/courses/${slug}?include=modules,lessons,materials,webinars,teachers,communications`
  return await $apiClient.get(url)
})

export const getLesson = createAsyncThunk(`courses/getLesson`, async (slug) => {
  const url = `/lessons/${slug}?include=materials,teachers`
  return await $apiClient.get(url)
})

export const finishLesson = createAsyncThunk(`courses/finishLesson`, async ({slug, payload}) => {
  const url = `/lessons/${slug}/complete`
  return await $apiClient.post(url, payload)
})

