import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {languagesList, DEFAULT_LOCALE} from './data'
import axios from 'axios'

const getLocaleKey = (isRequest = false) => {
  const storageKey = localStorage.getItem('i18nextLng')
  return !storageKey ? DEFAULT_LOCALE : isRequest && storageKey === 'uk' ? 'ua' : storageKey
}

const loadCurrent = (url, options, callback, data) => {
  let apiTranslation = null
  axios.get(url, {headers: options.customHeaders}).then((apiResponse) => {
    apiTranslation = apiResponse.data
  }).finally(() => {
    axios.get(`${process.env.PUBLIC_URL}/assets/data/locales/${getLocaleKey(false)}.json`).then(({data}) => {
      callback((apiTranslation ? Object.assign({}, apiTranslation, data) : data), {status: 200})
    })
  })
}

i18n
  // Enables the i18next backend
  .use(Backend)
  // Enable automatic language detection
  .use(LanguageDetector)
  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.REACT_APP_API_URL}/api/v1/translations`,
      // loadPath: `https://api.delav.hdit.info/api/v1/translations`,
      // loadPath: `${process.env.PUBLIC_URL}/assets/data/locales/${getLocaleKey()}.json`,
      // loadPath: ``,
      customHeaders: {
        Accept: 'application/json',
        'X-localization': getLocaleKey(true)
      },
      parse(apiTranslation) {
        return apiTranslation
      },

      ajax: loadCurrent
    },
    react: {
      useSuspense: true
    },
    supportedLngs: languagesList.map(lang => lang.id),
    load: 'languageOnly',
    lng: getLocaleKey(false),
    fallbackLng: false,
    debug: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    }
  })

export default i18n
