import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet-async'

export const AppSEO = ({title, description}) => {
  return (
    <Helmet>
      <title>
        {title ? title : 'Illuminatum'}
      </title>
      <meta
        name="description"
        content={
          description ? description : 'Innovations in education'
        }
      />
    </Helmet>
  )
}
