import {$apiClient} from '../../../utility/axios'
import {createAsyncThunk} from '@reduxjs/toolkit'

export const login = createAsyncThunk(`auth/login`, async (payload) => {
  const url = '/auth/login'
  return await $apiClient.post(url, payload)
})

export const logout = createAsyncThunk(`auth/logout`, async () => {
  const url = '/auth/logout'
  return await $apiClient.post(url)
})

export const signIn = createAsyncThunk(`auth/signIn`, async (payload) => {
  const url = '/auth/register'
  return await $apiClient.post(url, payload)
})

export const changePassword = createAsyncThunk(`auth/changePassword`, async (payload) => {
  const url = '/auth/password/change'
  return await $apiClient.post(url, payload)
})

export const resetPassword = createAsyncThunk(`auth/resetPassword`, async (payload) => {
  const url = '/auth/password/reset'
  return await $apiClient.post(url, payload)
})

export const restorePassword = createAsyncThunk(`auth/restorePassword`, async (payload) => {
  const url = '/auth/password/restore'
  return await $apiClient.post(url, payload)
})

export const setAuthToken = (token) => {
  localStorage.setItem('access_token', token)
  $apiClient.defaults.headers['Authorization'] = `Bearer ${token}`
}

export const deleteAuthToken = () => {
  localStorage.removeItem('access_token')
  delete $apiClient.defaults.headers['Authorization']
}
