import {createSlice} from '@reduxjs/toolkit'
import {initialState} from './state'
import * as services from './services'

// Creating slice with reducers
const slice = createSlice({
  name: 'webinars',
  initialState,
  reducers: {},
  extraReducers: {
    [services.getWebinars.pending]: (state, action) => {
      if (action.meta.arg && action.meta.arg.reset) {
        state.webinars.data = []
        state.webinars.meta = null
      }
      state.webinars.isLoading = true
      state.webinars.isSuccess = false
      state.webinars.error = null
    },
    [services.getWebinars.rejected]: (state, action) => {
      state.webinars.isLoading = false
      state.webinars.error = action.payload
    },
    [services.getWebinars.fulfilled]: (state, action) => {
      state.webinars.isLoading = false
      state.webinars.isSuccess = true
      state.webinars.data = action.payload.data
      state.webinars.meta = action.payload.meta
    },

    [services.getWebinar.pending]: (state) => {
      state.webinar.isLoading = true
      state.webinar.isSuccess = false
      state.webinar.error = null
    },
    [services.getWebinar.rejected]: (state, action) => {
      state.webinar.isLoading = false
      state.webinar.error = action.payload
    },
    [services.getWebinar.fulfilled]: (state, action) => {
      state.webinar.isLoading = false
      state.webinar.isSuccess = true
      state.webinar.data = action.payload.data
    }
  }
})

export default slice.reducer
