// The initial state of the Channels container
export const initialState = {
  communications: {
    data: [],
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  },
  communication: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  },
  communicationToken: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  },
  cometChatInit: {
    data: false,
    isLoading: false,
    isSuccess: false,
    error: null
  },
  cometChatLogin: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null
  },
  banMember: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  unbanMember: {
    isLoading: false,
    isSuccess: false,
    error: null
  }
}
