import {createSlice} from '@reduxjs/toolkit'
import {initialState} from './state'
import * as services from './services'

// Creating slice with reducers
const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: {
    [services.getContacts.pending]: (state, action) => {
      if (action.meta.arg && action.meta.arg.reset) {
        state.contacts.data = []
        state.contacts.meta = null
      }
      state.contacts.isLoading = true
      state.contacts.isSuccess = false
      state.contacts.error = null
    },
    [services.getContacts.rejected]: (state, action) => {
      state.contacts.isLoading = false
      state.contacts.error = action.payload
    },
    [services.getContacts.fulfilled]: (state, action) => {
      state.contacts.isLoading = false
      state.contacts.isSuccess = true
      state.contacts.data = action.payload.data
      state.contacts.meta = action.payload.meta
    }
  }
})

export default slice.reducer
