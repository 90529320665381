import {$apiClient} from '../../../utility/axios'
import {objectToQueryString} from '../../../utility/commons'
import {createAsyncThunk} from '@reduxjs/toolkit'

export const getWebinars = createAsyncThunk(`webinars/getWebinars`, async ({params}) => {
  const query = Object.assign({
    per_page: 9
  }, params)

  let url = '/webinars'
  url = objectToQueryString(url, query)
  return await $apiClient.get(url)
})

export const getWebinar = createAsyncThunk(`webinars/getWebinar`, async (slug) => {
  const url = `/webinars/${slug}?include=teachers`
  return await $apiClient.get(url)
})
