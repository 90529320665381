import {$apiClient} from '../../../utility/axios'
import {createAsyncThunk} from '@reduxjs/toolkit'
import {objectToQueryString} from '../../../utility/commons'

export const getFaq = createAsyncThunk(`faq/getFaq`, async () => {
  const query = {include: 'faqs'}
  let url = '/categories'
  url = objectToQueryString(url, query)
  return await $apiClient.get(url)
})
