// ** Reducers Imports
// dev START
import navbar from './navbar'
import layout from './layout'
import todo from '@src/views/dev/apps/todo/store'
import chat from '@src/views/dev/apps/chat/store'
import users from '@src/views/dev/apps/user/store'
import email from '@src/views/dev/apps/email/store'
import kanban from '@src/views/dev/apps/kanban/store'
import invoice from '@src/views/dev/apps/invoice/store'
import calendar from '@src/views/dev/apps/calendar/store'
import ecommerce from '@src/views/dev/apps/ecommerce/store'
import dataTables from '@src/views/dev/tables/data-tables/store'
import permissions from '@src/views/dev/apps/roles-permissions/store'
// dev END
import courses from './modules/courses/slice'
import webinars from './modules/webinars/slice'
import auth from './modules/auth/slice'
import user from './modules/user/slice'
import communications from './modules/communications/slice'
import subscriptions from './modules/subscriptions/slice'
import purchases from './modules/purchases/slice'
import faq from './modules/faq/slice'
import contacts from './modules/contacts/slice'

const rootReducer = {
  // dev START
  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  // dev END
  courses,
  webinars,
  auth,
  user,
  communications,
  subscriptions,
  purchases,
  faq,
  contacts
}

export default rootReducer
