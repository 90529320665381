import {createSlice} from '@reduxjs/toolkit'
import {initialState} from './state'
import * as services from './services'
import {banMember, unbanMember} from './services'

// Creating slice with reducers
const slice = createSlice({
  name: 'communications',
  initialState,
  reducers: {},
  extraReducers: {
    [services.getCommunications.pending]: (state, action) => {
      if (action.meta.arg && action.meta.arg.reset) {
        state.communications.data = []
        state.communications.meta = null
      }
      state.communications.isLoading = true
      state.communications.isSuccess = false
      state.communications.error = null
    },
    [services.getCommunications.rejected]: (state, action) => {
      state.communications.isLoading = false
      state.communications.error = action.payload
    },
    [services.getCommunications.fulfilled]: (state, action) => {
      state.communications.isLoading = false
      state.communications.isSuccess = true
      state.communications.data = action.payload.data
      state.communications.meta = action.payload.meta
    },

    [services.getCommunication.pending]: (state, action) => {
      if (action.meta.arg && action.meta.arg.reset) {
        state.communications.data = []
      }
      state.communication.isLoading = true
      state.communication.isSuccess = false
      state.communication.error = null
    },
    [services.getCommunication.rejected]: (state, action) => {
      state.communication.isLoading = false
      state.communication.error = action.payload
    },
    [services.getCommunication.fulfilled]: (state, action) => {
      state.communication.isLoading = false
      state.communication.isSuccess = true
      state.communication.data = action.payload.data
    },

    // token
    [services.getCommunicationToken.pending]: (state) => {
      state.communicationToken.isLoading = true
      state.communicationToken.isSuccess = false
      state.communicationToken.error = null
    },
    [services.getCommunicationToken.rejected]: (state, action) => {
      state.communicationToken.isLoading = false
      state.communicationToken.error = action.payload
    },
    [services.getCommunicationToken.fulfilled]: (state, action) => {
      state.communicationToken.isLoading = false
      state.communicationToken.isSuccess = true
      state.communicationToken.data = action.payload.data
    },

    // initCometChat
    [services.initCometChat.pending]: (state) => {
      state.cometChatInit.isLoading = true
      state.cometChatInit.isSuccess = false
      state.cometChatInit.error = null
    },
    [services.initCometChat.rejected]: (state, action) => {
      state.cometChatInit.isLoading = false
      state.cometChatInit.error = action.payload
    },
    [services.initCometChat.fulfilled]: (state, action) => {
      state.cometChatInit.isLoading = false
      state.cometChatInit.isSuccess = true
    },

    // loginCometChat
    [services.loginCometChat.pending]: (state) => {
      state.cometChatLogin.isLoading = true
      state.cometChatLogin.isSuccess = false
      state.cometChatLogin.error = null
    },
    [services.loginCometChat.rejected]: (state, action) => {
      state.cometChatLogin.isLoading = false
      state.cometChatLogin.error = action.payload
    },
    [services.loginCometChat.fulfilled]: (state, action) => {
      state.cometChatLogin.isLoading = false
      state.cometChatLogin.isSuccess = true
    },

    // banMember
    [services.banMember.pending]: (state) => {
      state.banMember.isLoading = true
      state.banMember.isSuccess = false
      state.banMember.error = null
    },
    [services.banMember.rejected]: (state, action) => {
      state.banMember.isLoading = false
      state.banMember.error = action.payload
    },
    [services.banMember.fulfilled]: (state, action) => {
      state.banMember.isLoading = false
      state.banMember.isSuccess = true
    },

    // unbanMember
    [services.unbanMember.pending]: (state) => {
      state.unbanMember.isLoading = true
      state.unbanMember.isSuccess = false
      state.unbanMember.error = null
    },
    [services.unbanMember.rejected]: (state, action) => {
      state.unbanMember.isLoading = false
      state.unbanMember.error = action.payload
    },
    [services.unbanMember.fulfilled]: (state, action) => {
      state.unbanMember.isLoading = false
      state.unbanMember.isSuccess = true
    }
  }
})

export default slice.reducer
