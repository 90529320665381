// The initial state of the purchases container
export const initialState = {
  purchaseCourse: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  }
}
