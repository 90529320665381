import {$apiClient} from '../../../utility/axios'
import {createAsyncThunk} from '@reduxjs/toolkit'
import {getCommunications} from '../communications/services'
import {objectToQueryString} from '../../../utility/commons'

export const getUser = createAsyncThunk(`user/getUser`, async () => {
  const url = '/auth/me?include=plans'
  return await $apiClient.get(url)
})

export const updateUser = createAsyncThunk(`user/updateUser`, async (payload) => {
  const url = '/user/update'
  return await $apiClient.put(url, payload)
})

export const updateUserPhoto = createAsyncThunk(`user/updateUserPhoto`, async (payload) => {
  const url = '/user/upload-photo'
  return await $apiClient.post(url, payload)
})

export const changePassword = createAsyncThunk(`user/changePassword`, async (payload) => {
  const url = '/auth/password/change'
  return await $apiClient.post(url, payload)
})

export const logout = createAsyncThunk(`auth/logout`, async () => {
  const url = '/auth/logout'
  return await $apiClient.post(url)
})

export const deleteAuthToken = () => {
  localStorage.removeItem('access_token')
  delete $apiClient.defaults.headers['Authorization']
}

export const getUserCourses = createAsyncThunk(`user/getUserCourses`, async ({params}) => {
  const query = Object.assign({
    per_page: 9
  }, params)

  let url = '/user/courses'
  url = objectToQueryString(url, query)
  return await $apiClient.get(url)
})

export const getUserCommunications = createAsyncThunk(`user/getUserChats`, async ({params}) => {

  const query = Object.assign({
    per_page: 9,
    include: 'plan,course'
  }, params)

  let url = '/user/communications'
  url = objectToQueryString(url, query)
  return await $apiClient.get(url)
})

export const getUserWebinars = createAsyncThunk(`user/getUserChannels`, async ({params}) => {
  const query = Object.assign({
    per_page: 9
  }, params)

  let url = '/user/webinars'
  url = objectToQueryString(url, query)
  return await $apiClient.get(url)
})

export const getUserModerating = createAsyncThunk(`user/getUserModerating`, async ({params}) => {
  const query = Object.assign({
    per_page: 8,
    include: 'plan,course'
  }, params)

  let url = '/user/communications/moderating'
  url = objectToQueryString(url, query)
  return await $apiClient.get(url)
})
