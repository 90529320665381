export const DEFAULT_LOCALE = 'en'

export const languagesList = [
  {
    id: 'en',
    short: 'Eng.',
    label: 'English',
    currencySymbol: '$'
  },
  {
    id: 'uk',
    short: 'Укр.',
    label: 'Українська',
    currencySymbol: '₴'
  },
  {
    id: 'ru',
    short: 'Рус.',
    label: 'Русский',
    currencySymbol: '₽'
  }
]
