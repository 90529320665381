// core
import {Spinner} from 'reactstrap'
import classnames from 'classnames'

// styles
import './index.scss'

// data
const loaderSize = '2.5rem'

const Loader = ({color, size, height, bg, className}) => {
  return (
    <div className={classnames('app-loader', {'app-loader--bg': !!bg}, className)}
         style={{minHeight: height ? height : 'auto'}}>
      <Spinner color={color ? color : 'primary'}
               style={{height: size ? size : loaderSize, width: size ? size : loaderSize}}/>
    </div>
  )
}

export default Loader
