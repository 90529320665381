import {$apiClient} from '../../../utility/axios'
import {createAsyncThunk} from '@reduxjs/toolkit'
import {objectToQueryString} from '../../../utility/commons'
import {CometChat} from '@cometchat-pro/chat'

const cometChatID = process.env.REACT_APP_COMETCHAT_APP_ID
const cometChatRegion = process.env.REACT_APP_COMETCHAT_REGION

export const getCommunications = createAsyncThunk(`communications/getCommunications`, async ({params}) => {
  const query = Object.assign({
    per_page: 9,
    include: 'plan,course'
  }, params)

  let url = '/communications'
  url = objectToQueryString(url, query)
  return await $apiClient.get(url)
})

export const getCommunication = createAsyncThunk(`communications/getCommunication`, async ({guid}) => {
  const query = {
    include: 'plan,course'
  }
  let url = `/communications/${guid}`
  url = objectToQueryString(url, query)
  return await $apiClient.get(url)
})

export const getCommunicationToken = createAsyncThunk(`communications/getCommunicationToken`, async (id) => {
  const url = `/communications/user/token`
  return await $apiClient.get(url)
})

export const initCometChat = createAsyncThunk(`communications/cometChatInit`, async () => {
  const appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(cometChatRegion)
    .autoEstablishSocketConnection(true)
    .build()

  return new Promise((resolve, reject) => {
    if (!CometChat.isInitialized()) {
      CometChat.init(cometChatID, appSetting).then(() => {
          if (CometChat.setSource) {
            CometChat.setSource('ui-kit', 'web', 'reactjs')
          }
          resolve()
          console.log('Initialization completed successfully')
        }, error => {
          reject()
          console.log('Initialization failed with error:', error)
        }
      )
    } else {
      resolve()
    }
  })
})

export const loginCometChat = createAsyncThunk(`communications/loginCometChat`, async (authToken) => {
  return new Promise((resolve, reject) => {
    if (CometChat.isInitialized()) {
      CometChat.login(authToken).then(
        user => {
          console.log('Login Successful:', {user})
          console.log('Login Successful')
          resolve(user)
        },
        error => {
          console.log('Login failed with exception:', {error})
          reject()
        }
      )
    } else {
      console.log('Initialization failed')
      reject()
    }
  })
})

/**
 * @body
 * uid: number
 * time: null || number, //3 6 12 null (null = forever)
 * **/
export const banMember = createAsyncThunk(`communications/banMember`, async ({guid, payload}) => {
  const url = `/communications/${guid}/ban`
  return await $apiClient.post(url, payload)
})

export const unbanMember = createAsyncThunk(`communications/unbanMember`, async ({guid, uid}) => {
  const url = `/communications/${guid}/unban/${uid}`
  return await $apiClient.post(url)
})
