import {createSlice} from '@reduxjs/toolkit'
import {initialState} from './state'
import * as services from './services'

// Creating slice with reducers
const slice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    clearPurchaseCourse: state => {
      state.purchaseCourse.data = null
    }
  },
  extraReducers: {
    [services.purchaseCourse.pending]: (state, action) => {
      state.purchaseCourse.isLoading = true
      state.purchaseCourse.isSuccess = false
      state.purchaseCourse.error = null
      if (action.meta.arg && action.meta.arg.slug) {
        state.purchaseCourse.data = action.meta.arg.slug
      }
    },
    [services.purchaseCourse.rejected]: (state, action) => {
      state.purchaseCourse.isLoading = false
      state.purchaseCourse.error = action.payload
      state.purchaseCourse.data = null
    },
    [services.purchaseCourse.fulfilled]: (state, action) => {
      state.purchaseCourse.isLoading = false
      state.purchaseCourse.isSuccess = true
      if (action.meta.arg && action.meta.arg.slug) {
        state.purchaseCourse.data = action.meta.arg.slug
      }
    }
  }
})
export const {clearPurchaseCourse} = slice.actions

export default slice.reducer
