// The initial state of the Webinar container
export const initialState = {
  iAuth: !!localStorage.getItem('access_token'),
  token: localStorage.getItem('access_token') || null,
  user: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null
  },
  update: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  photo: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  password: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  logout: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  courses: {
    data: [],
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  },
  communications: {
    data: [],
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  },
  webinars: {
    data: [],
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  },
  moderating: {
    data: [],
    isLoading: false,
    isSuccess: false,
    error: null,
    meta: null
  }
}
